import React, { useContext, useState } from 'react'

const initialValue = {
  articles: [],
  setArticles: () => null,
}

const ArticleContext = React.createContext<articleType>(initialValue)

export const ArticleContextProvider = ({ children }: articleProp) => {
  const [articles, setArticles] = useState([])

  return (
    <ArticleContext.Provider value={{ articles, setArticles }}>{children}</ArticleContext.Provider>
  )
}

export const useArticleContext = () => {
  const { articles, setArticles } = useContext(ArticleContext)
  return { articles, setArticles }
}

type articleProp = {
  children: React.ReactNode
}

type articleType = {
  articles: any[]
  setArticles: CallableFunction
}
