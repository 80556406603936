import './button.scss'

const CustomButton = (props: any) => {
  const { title, className, onClick } = props

  return (
    <div>
      <button onClick={onClick} className={`view-load-more ${className}`}>
        {title}
      </button>
    </div>
  )
}

export default CustomButton
