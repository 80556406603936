import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getHtml, getMetaData } from '../../../helper/utils'
import { getArticleById } from '../../../api/Category.service'
import { useCategoryContext } from '../../../context/Context'
import Loader from '../../../components/Loader/Loader'
import Donate from '../../Donate/donate'
import Blog from '../../Stories/Blog/Blog'
import NoDataFound from '../../../components/NoDataFound/NoDataFound'
import { landingId } from '../../../helper/constant'
import { Helmet } from 'react-helmet-async'
import { Carousel } from 'bootstrap'
import { getTags } from '../../../api/tags.service'
import ReactDOM from 'react-dom' // Import ReactDOM
import CarouselOwl from '../../../components/Carousel/Owlcarousel'
import HeroBannerSlider from '../../../components/HeroBanneSlider/HeroBannerSlider'
import PageNotFound from '../../../components/PageNotFound/PageNotFound'

const Category: React.FC = () => {
  const [data, setdata] = useState<any>({})
  const { id } = useParams()
  const { category, setcategory } = useCategoryContext()
  const [loader, setloader] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [uid, setUid] = useState('')

  const hanldeCarouselCLick = (item: any) => {
    navigate(`/blog/${item?.uid}`)
  }

  const isBlogPage = location.pathname.split('/').includes('blog')
  useEffect(() => {
    if (!isBlogPage) {
      return
    }
    if (isBlogPage) {
      return navigate(`/blog`)
    }
  }, [isBlogPage])

  useEffect(() => {
    const myCarouselElement: Element | null = document.querySelector('#carouselExampleCaptions')
    if (myCarouselElement) {
      const carousel = new Carousel(myCarouselElement, {
        interval: 1000,
        touch: false,
        wrap: true,
      })
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const findTagValue = () => {
      const sliders = document.querySelectorAll('.carousel-blog-section')

      if (sliders.length) {
        sliders.forEach((slider) => {
          const id = slider.getAttribute('id')
          const tagValue = slider.getAttribute('tag')
          const title = slider.getAttribute('title')
          const description = slider.getAttribute('description')
          const showmore = slider.getAttribute('show_learn_more')
          const showNav = slider.getAttribute('show_nav')

          if (tagValue && id) {
            const getData = async (tagValue: any) => {
              let res = await getTags(tagValue)
              const content = res?.data?.article

              // Get the target element by its ID
              var targetElement = document.getElementById(id)
              // Inject HTML using innerHTML

              if (targetElement) {
                ReactDOM.render(
                  <CarouselOwl
                    data={content}
                    title={title}
                    showmore={showmore}
                    handleClick={hanldeCarouselCLick}
                    description={description}
                    showNav={showNav}
                  />,
                  targetElement,
                )
              }
            }

            getData(tagValue)
          } else {
            console.log(' Tag attribute not found on the element')
          }
        })
      } else {
        timeoutId = setTimeout(findTagValue, 100)
      }
    }

    findTagValue()

    // Cleanup: Clear the timeout if the component unmounts or the slider is found
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (!id || id === undefined) {
      setUid(landingId)
    } else {
      setUid(id)
    }
  }, [id, navigate])

  useEffect(() => {
    if (category?.length > 0) {
      setdata(category?.find((item) => item?.uid === uid))
    }
    const getData = async () => {
      setloader(true)
      try {
        if (category?.findIndex((item) => item?.uid === uid) === -1) {
          let result: any = await getArticleById(uid)

          if (!!result && result?.data && result?.error === false) {
            setcategory((prev: any) => [...prev, result?.data])
          }
          setloader(false)
        }

        setloader(false)
      } catch (error: any) {
        if (error?.response?.data?.error?.status === 403) {
          let result: any = await getArticleById(uid, true)
          if (!!result && !result.error && result?.data) {
            setcategory((prev: any) => [...prev, result?.data])
          }
        }
        console.error('Error fetching data:', error)
        setloader(false)
      } finally {
        setloader(false)
      }
    }

    getData()
  }, [uid, category, setcategory, navigate])

  useEffect(() => {
    $('#carouselSlider').ready(function () {
      $('.owl-prev').html('<i class="fa fa-chevron-left"></i>')
      $('.owl-next').html('<i class="fa fa-chevron-right"></i>')
      $('.owl-carousel').owlCarousel({
        responsiveClass: true,
        margin: 10,
        dotsEach: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 3,
            loop: true,
          },
          1000: {
            items: 3,
            loop: true,
            nav: true,
          },
        },
      })
    })
    $('.superblockslider__track > p').replaceWith(function () {
      return $(this).contents()
    })
  }, [uid, category, setcategory])

  const getComponent = () => {
    const components: React.ReactNode[] = []

    const item = category?.find((item) => item?.uid === id)
    const title = item?.title

    // Define a mapping of titles to components
    const titleToComponent: Record<string, React.ReactNode> = {
      Blog: <Blog />,
    }

    // Check if the title exists in the mapping and push the component if found
    if (title && titleToComponent[title]) {
      components.push(titleToComponent[title])
    }

    return components
  }
  /**
   * Commenting this section as making this dynamic from backend
   */
  // if (uid === 'donate') {
  //   return <Donate />
  // }

  if (loader) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }

  const isEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object

  if (data === undefined || isEmpty(data)) {
    return (
        <PageNotFound />  
    )
  }
  
  return (
    <div className='category'>
      {data?.hero_banner_slider && data?.hero_banner_slider.length && (
        <HeroBannerSlider data={data?.hero_banner_slider} />
      )}

      <Helmet>
        <link rel='canonical' href={data?.canonical_url}></link>
        <title>{data.meta_title}</title>
        <meta name='description' content={data.meta_description} />
        <meta name='keywords' content={data.meta_key} />
      </Helmet>

      {data?.content && <div dangerouslySetInnerHTML={getHtml(data?.content)} />}
      {getComponent()}
    </div>
  )
}

export default Category
