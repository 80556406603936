import React, { useContext, useState } from 'react'

const initialValue = {
  active: '',
  setActive: () => null,
  data: [],
  setData: () => null,
}

const StoryContext = React.createContext<storyType>(initialValue)

export const StoryContextProvider = ({ children }: storyProp) => {
  const [active, setActive] = useState('')
  const [data, setData] = useState([])
  return (
    <StoryContext.Provider value={{ active, setActive, data, setData }}>
      {children}
    </StoryContext.Provider>
  )
}

export const useStoryContext = () => {
  const { active, setActive, data, setData } = useContext(StoryContext)
  return { active, setActive, data, setData }
}

type storyProp = {
  children: React.ReactNode
}

type storyType = {
  active: string
  setActive: CallableFunction
  data: any[]
  setData: CallableFunction
}
