import axios from 'axios'
import { END_POINTS } from './domain'

export const getMenus = async (auth?: boolean) => {
  try {
    let result = await axios.get(`${END_POINTS.menus}`, {
      headers: { auth: auth },
    })
    return {
      data: result?.data,
      error: false,
    }
  } catch (error) {
    return {
      error,
    }
  }
}
