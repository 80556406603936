import OwlCarousel from 'react-owl-carousel'
import './carousel.scss'
import CarouselImageCard from './Card/CarouselImagecard'

const CarouselOwl = (props: any) => {
  const { data, title, showmore, handleClick, description, showNav } = props

  //Owl Carousel Settings
  const options = {
    responsiveClass: true,
    margin: 10,
    dotsEach: true,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
        loop: false,
      },
      1000: {
        items: 3,
        loop: false,
        nav: true,
      },
    },
  }

  return (
    <>
      {!!title && <h2 style={{ textTransform: 'capitalize' }}>{title}</h2>}
      {!!description && <h5 style={{ textTransform: 'capitalize' }}>{description}</h5>}
      <section
        className={`carousel-section bottom-40 ${
          showNav === 'true' ? 'show-navigation' : 'hide-navigation'
        }`}
      >
        <div className='container'>
          <div className='row'>
            <div className='large-12 columns' data-aos='fade-up' data-aos-delay='100'>
              <OwlCarousel className='owl-theme learningCarousel owl-loaded owl-drag' {...options}>
                <div className='owl-stage-outer'>
                  <div
                    className='owl-stage'
                    style={{
                      transition: 'all 0s ease 0s',
                    }}
                  >
                    {data?.map((item: any, index: any) => {
                      if (!item?.tags?.includes('carousel-item')) {
                        return null
                      }
                      return (
                        <div
                          className='owl-item'
                          style={{ width: '345.333px', marginRight: '10px' }}
                        >
                          <CarouselImageCard
                            item={item}
                            index={index}
                            showmore={showmore}
                            handleClick={handleClick}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CarouselOwl
