import React from 'react'
import Image from '../../Image/Image'

const CarouselImageCard: React.FC<CarouselImageCardProps> = ({
  item,
  index,
  showmore,
  handleClick,
}) => {
  const onCardClicked = (item: any) => {
    handleClick(item)
  }
  return (
    <div key={index} className='carousel-card'>
      <Image
        src={item?.heroBanner}
        alt='story-banner'
        className='story__image blog-img'
      />
      <h2 className='carousel-card__heading' onClick={() => onCardClicked(item)}>
        {item?.title.length > 60 ?item?.title.substring(0,60) + "..." : item?.title}
      </h2>

      {!!showmore && (
        <button className='view-load-more' onClick={() => onCardClicked(item)}>
          Learn more
        </button>
      )}
    </div>
  )
}

type CarouselImageCardProps = {
  item: any
  index: any
  showmore?: any
  handleClick: any
}

export default CarouselImageCard
