import React, { useEffect } from 'react'
import Router from './router'
import { CategoryContextProvider } from './context/Context'
import { MenuContextProvider } from './context/MenuContext'
import { ArticleContextProvider } from './context/ArticleContext'
import './style/style.min.css'
import './App.scss'
import './style/style.scss'
import './style/cms.scss'
import './style/ticketdonations.scss'
import { StoryContextProvider } from './context/StoryContext'
import { HelmetProvider } from 'react-helmet-async'

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top when component mounts
  }, [])

  return null // No need to render anything
}

function App() {
  // useEffect(() => {
  //   window.scrollTo(0, 0) // Scroll to top when component mounts
  // }, [])
  return (
    <HelmetProvider>
      <StoryContextProvider>
        <ArticleContextProvider>
          <CategoryContextProvider>
            <MenuContextProvider>
              <div className='App'>
                <ScrollToTop />
                <Router />
              </div>
            </MenuContextProvider>
          </CategoryContextProvider>
        </ArticleContextProvider>
      </StoryContextProvider>
    </HelmetProvider>
  )
}

export default App
