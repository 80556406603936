import React, { useEffect } from 'react'
import { BrowserRouter as MainRouter, Route, Routes } from 'react-router-dom'
import { routes } from './routes'

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top when component mounts
  }, [])

  return null // No need to render anything
}

const getRoutes = (routes: any) => {
  return routes?.map((route: any, index: number) => {
    return (
      <Route key={index} path={route.path} element={route.element}>
        {getRoutes(route.routes)}
      </Route>
    )
  })
}

const Router = () => {
  return (
    <MainRouter>
      <ScrollToTop />
      <Routes>{getRoutes(routes)}</Routes>
    </MainRouter>
  )
}

export default Router
