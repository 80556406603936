import { useNavigate, useParams } from 'react-router-dom'
import './storydetails.scss'
import { useCallback, useEffect, useState } from 'react'
import { getArticleById } from '../../../api/Category.service'
import { getHtml } from '../../../helper/utils'
import Image from '../../../components/Image/Image'
import NoDataFound from '../../../components/NoDataFound/NoDataFound'
import Loader from '../../../components/Loader/Loader'
import { getTagList } from '../../../api/tags.service'
import CustomButton from '../../../components/Button/Button'
import { useMenuContext } from '../../../context/MenuContext'
import PageNotFound from '../../../components/PageNotFound/PageNotFound'

const StoryDetails = () => {
  const { menu } = useMenuContext()

  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedTag, setSeletectedTag] = useState('')

  const [storyArticle, setStoryArticle] = useState<any>()
  const [status, setStatus] = useState<{ loading: boolean; error: string | null }>({
    loading: true,
    error: null,
  })

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top when component mounts
  }, [id])

  const [realtedStories, setRelatedStories] = useState([])

  const fetchRelatedStories = useCallback(async () => {
    try {
      const res: any = await getTagList(storyArticle?.tags)
      if (!res?.error) {
        console.group(res)
        setRelatedStories(res?.data?.article)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: 'Error fetching data' })
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.status === 403) {
        const res: any = await getTagList(storyArticle?.tags, true)
        if (!res?.error) {
          setRelatedStories(res?.data?.article)
        }
      }
      setStatus({ loading: false, error: 'An error occurred while fetching data' })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }, [storyArticle])

  const fetchArticle = useCallback(async () => {
    try {
      const res: any = await getArticleById(id)
      if (!res?.error) {
        setStoryArticle(res?.data)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: 'Error fetching data' })
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.status === 403) {
        const res: any = await getArticleById(id, true)
        if (!res?.error) {
          setStoryArticle(res?.data)
        }
      }
      setStatus({ loading: false, error: 'An error occurred while fetching data' })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }, [id])

  useEffect(() => {
    fetchArticle()
  }, [id, fetchArticle])

  useEffect(() => {
    fetchRelatedStories()
  }, [storyArticle])

  if (status?.loading) {
    return <Loader />
  }

  if (status.error) {
    return <div>Error: {status.error}</div>
  }

  const handleBadgeClick = (tag: any) => {
    setSeletectedTag(tag)
  }

  const temp = menu?.find((item) => item?.category === 'blog')

  const blogUid = temp?.uid

  return (
    <div className='blog-details-container'>
      <div className='blog-details'>
        <h2 className='blog-story-heading'>{storyArticle?.title}</h2>
        {storyArticle?.content && <div className='spacer'></div>}
        <div className='story-banner'>
          {storyArticle?.heroBanner && (
            <section className='home'>
              <div id='carouselExampleCaptions' className='carousel slide'>
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <Image src={storyArticle?.heroBanner} className='d-block w-100' alt='banner' />
                    <div className='carousel-caption d-none d-md-block'>
                      <h1>{storyArticle?.meta_description}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        {storyArticle?.content ? (
          <div dangerouslySetInnerHTML={getHtml(storyArticle?.content)} />
        ) : (
          <PageNotFound />  
        )}
      </div>

      <div className='see-all-stories'>
        <div className='left-section'>
          <div className='title'>All posts tagged</div>
          <div className='badge-container'>
            {storyArticle?.tags
              ?.filter((i: any) => i !== 'carousel-item')
              ?.map((tag: any, idx: any) => {
                return (
                  <div
                    className={`stories-badge-wrapper `}
                    key={idx}
                    onClick={() => handleBadgeClick(tag)}
                  >
                    <div
                      className={`single-story-badge ${selectedTag === tag ? 'selected' : ''}`}
                      onClick={() => navigate(`/blog/tag/${encodeURIComponent(tag)}`)}
                    >
                      {tag}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div>
          <button onClick={() => navigate(`/content/${blogUid}`)} className={`view-load-more`}>
            SEE ALL STORIES
          </button>
        </div>
      </div>

      <div className='light-pink-bg'>
        <div className='category-container'>
          <h2 className='item-title'>Related Stories</h2>
          <div className='story-wrapper'>
            {realtedStories?.slice(0, 3)?.map((item: any, index: any) => {
              return (
                <div className='story' key={index}>
                  <Image src={item?.heroBanner} alt='story-banner' className='story__image' />
                  <h3 className='story__title'>
                    {item?.title.length > 60 ? item?.title.substring(0, 60) + '...' : item?.title}
                  </h3>
                  <CustomButton
                    title='Read More'
                    onClick={() => navigate(`/blog/${item?.uid}`)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryDetails
