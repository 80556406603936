import React, { useEffect, useState } from 'react'
import './footer.scss'
import Delta from '../../../components/Delta/Delta'
import { getFooter } from '../../../api/footer.service'
import { getHtml } from '../../../helper/utils'

const Footer: React.FC = () => {
  const [data, setdata] = useState<any>([])

  useEffect(() => {
    const getData = async () => {
      try {
        let result: any = await getFooter()
        if (!result?.error) {
          setdata(result?.data)
        }
      } catch (error: any) {
        if (error?.response?.data?.error?.status === 403) {
          let result: any = await getFooter(true)
          if (!result?.error) {
            setdata(result?.data)
          }
        }
      }
    }
    getData()
  }, [])

  return (
    <div>
      {/* <Delta /> */}
      {data?.article && <div dangerouslySetInnerHTML={getHtml(data?.article?.content)} />}
    </div>
  )
}

export default Footer
