import React, { Component, ReactNode } from "react";
import { alert } from "../../helper/iconPath";
import "./errorboundry.scss"
import Image from "../Image/Image";
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMsg: string;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMsg: "" };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, errorMsg: info.componentStack });
  }

  render() {
    if (this.state.hasError) {
      return <div className="errorBoundry">
        <img src={alert} alt="" />
        <span>Something Went Wrong</span>
      </div>;
    }
    return this.props.children;
  }
}
