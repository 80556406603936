import axios from 'axios'
import { END_POINTS } from './domain'

export const getFooter = async (auth?: boolean) => {
        let result = await axios.get(`${END_POINTS.footer}`, {
            headers: { auth: auth }
        })
        if (result.data) {
            return {
                data: result.data,
                error: false,
            }           
        }
        return result
 
}
