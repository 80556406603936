import { useLocation, useNavigate, useParams } from 'react-router-dom'
import downArrow from './down_arrow_icon.svg'
import { useEffect, useState } from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import Dropdown from '../Dropdown/Dropdown'
import { useMenuContext } from '../../../../context/MenuContext'
import { useStoryContext } from '../../../../context/StoryContext'
import Loader from '../../../../components/Loader/Loader'

export const NavbarItems: React.FC<NavbarItemsProps> = ({
  setnavActive,
  navActive,
  setsubActiveNav,
  setnavMobile,
  navMobile,
}) => {
  const [navClick, setnavClick] = useState<any>(null)
  let location = useLocation()
  let isContentPage = location.pathname.includes('content/')
  let isDetailsPage = location.pathname.includes('/blog/')
  let isStoriesPage = location.pathname.includes('/blog/tag') 
  
  let width = useWindowSize()
  let isMobile = width < 1200
  let { menu, loader } = useMenuContext()
  const navigate = useNavigate()
  const { id } = useParams()
  const searchParams = new URLSearchParams(location.search)
  const tag = searchParams.get('tag')

  const [arrow, setArrow] = useState(false)
  const [temp, setTemp] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active, setActive } = useStoryContext()

  useEffect(() => {
    if (isMobile && navActive === undefined) {
      setnavActive(menu[0]?.uid)
    }
  }, [])

  useEffect(() => {
    if (isMobile && navActive === null) {
      setnavActive(menu[0]?.uid)
    }
  }, [width, navActive, setnavActive, isMobile, menu])

  useEffect(() => {
    if (menu?.length) {
      let navId = menu?.find((item) => item?.uid === id)

      let subNavId = menu?.find((item) =>
        item?.subCategory?.find((nitem: any) => nitem?.uid === id),
      )

      if (subNavId) {
        setsubActiveNav(id)
        setnavActive(subNavId.uid)
      }

      if (navId) {
        setnavActive(id)
        setsubActiveNav(null)
      }
    }
  }, [menu])

  useEffect(() => {
    if (active) {
      setsubActiveNav(active)
    }
  }, [active])

  const clickHandler = (uid: any) => {
    navClick === uid ? setnavClick(null) : setnavClick(uid)
    uid === navActive && !isContentPage ? setnavActive(null) : setnavActive(uid)
    if (uid === "dream-notes") {
      return navigate(`/get-involved/${uid}`)
    }
    if (uid === 'blog') {
      return navigate(`/${uid}`)
    }
    navigate(`/${uid}`)
    setnavMobile(false)
  }

  useEffect(() => {
    let navId = menu?.find((item) => item?.category === 'blog')

    if (isDetailsPage) {
      setnavActive(navId?.uid)
    }
  }, [menu])

  useEffect(() => {
    let navId = menu?.find((item) => item?.category === 'blog')
    let decodedTag = decodeURIComponent(tag || '')

    let subNavId = navId?.subCategory?.find(
      (nitem: any) => nitem?.title.toLowerCase() === decodedTag.toLowerCase(),
    )

    if (isStoriesPage) {
      setnavActive(navId?.uid)
      setsubActiveNav(subNavId?.uid)
    }
  }, [tag, menu])

  const onArrowLClick = (item: any) => {
    setArrow(!arrow)
    setTemp(item?.uid)
  }

  const sortedMenu = menu?.sort((a, b) => a?.nav_order - b?.nav_order)

  if (loader) {
    return <Loader />
  }

  return (
    <>
      {sortedMenu?.map((item: any, index: number) => {
        return (
          <div className='navBar__item' key={index}>
            <li className='nav-item'>
              <button className={`nav-link ${item?.uid === navActive && 'navActive'}`}>
                <div onClick={() => clickHandler(item?.uid)}>{item?.title}</div>
                <img
                  src={downArrow}
                  alt='icon'
                  onClick={() => onArrowLClick(item)}
                  className={`m-show 
                  ${temp === item?.uid ? 'mobile-arrow__open' : ` mobile-arrow`}`}
                />
              </button>
            </li>

            {isMobile && temp === item?.uid && (
              <Dropdown
                navIndex={item?.uid}
                isMobile={isMobile}
                setsubActiveNav={setsubActiveNav}
                setnavMobile={setnavMobile}
                setnavActive={setnavActive}
              />
            )}
          </div>
        )
      })}

      <div className='header-contact-form'>
        <h5>Contact Us</h5>
        <p>Stays up-to-date on the latest at Garden of Dreams Foundation.</p>
        <div className='signup-section'>
          <input type='text' placeholder='Sign Up' />
          <a href='#dfs' className='btn btn-orange'>
            Sign Up
          </a>
        </div>
      </div>
    </>
  )
}

type NavbarItemsProps = {
  setnavActive: (active: any) => void
  navActive: any
  setsubActiveNav: (active: any) => void
  setnavMobile: (active: any) => void
  navMobile: any
}
