export const formFields = [
  {
    label: 'First Name *',
    placeholder: 'first_name',
    inputType: 'input',
    name: 'first_name',
  },
  {
    label: 'Last Name *',
    placeholder: 'last_name',
    inputType: 'input',
    name: 'last_name',
  },
  {
    label: 'Email Address *',
    placeholder: 'email_address',
    inputType: 'email',
    name: 'email_address',
  },
  {
    label: `Yes, I would like to receive periodic
        updates and communications.`,
    placeholder: 'receive_updates',
    inputType: 'checkbox',
    id: 'flexCheckDefault5',
    className: 'form-check-input',
    labelClassName: 'form-check-label',
    htmlFor: 'flexCheckDefault5',
    name: 'receive_updates',
  },
  {
    label: `Please enter your Dream Note message below.
        Maximum response 255 characters, approx. 5
        rows of text.`,
    placeholder: 'dream_note',
    inputType: 'textArea',
    name: 'dream_note',
  },
]

export const attendanceTable = [
  {
    price: '$100',
    gameDetails: 'Tuesday, October 4, 2022 - Kicks vs. Pistons at 7:30PM (Pre-Season)',
    availability: 'Sorry, Dream Notes are sold out for this game!',
    quantity: 1,
    specialInfo: 'N/A',
    totalAmount: '$0.00',
  },
  {
    price: '$100',
    gameDetails: 'Tuesday, October 4, 2022 - Kicks vs. Pistons at 7:30PM (Pre-Season)',
    availability: 'Sorry, Dream Notes are sold out for this game!',
    quantity: 1,
    specialInfo: 'N/A',
    totalAmount: '$0.00',
  },
  {
    price: '$100',
    gameDetails: 'Tuesday, October 4, 2022 - Kicks vs. Pistons at 7:30PM (Pre-Season)',
    availability: 'Sorry, Dream Notes are sold out for this game!',
    quantity: 1,
    specialInfo: 'N/A',
    totalAmount: '$0.00',
  },
  {
    price: '$100',
    gameDetails: 'Tuesday, October 4, 2022 - Kicks vs. Pistons at 7:30PM (Pre-Season)',
    availability: 'Sorry, Dream Notes are sold out for this game!',
    quantity: 1,
    specialInfo: 'N/A',
    totalAmount: '$0.00',
  },
]

export const string =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

export const landingId = 'd19f0eb5-09f3-4275-bdc4-199601d4bd93'
