import React from 'react'

const HeroBannerSlider = ({ data }: any) => {
  return (
    <section className='landingPage hero-section '>
      <div
        id='carouselExampleCaptions'
        className='carousel slide'
        data-bs-ride='carousel'
        data-bs-interval='2000'
      >
        <div className='carousel-indicators'>
          {data &&
            data?.length &&
            data
              ?.sort((a: any, b: any) => a?.banner_order - b?.banner_order)
              ?.map((slide: any, index: number) => (
                <button
                  key={index}
                  className={`carousel-dot ${index === 0 ? 'active' : ''}`}
                  type='button'
                  data-bs-target='#carouselExampleCaptions'
                  data-bs-slide-to={index}
                  aria-current={index === 0 ? 'true' : undefined}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
        </div>
        <div className='carousel-inner'>
          {data &&
            data
              ?.sort((a: any, b: any) => a?.banner_order - b?.banner_order)
              ?.map((slide: any, index: number) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                  data-bs-interval='5000'
                >
                  <img className='d-block w-100' src={slide?.banner_ima} alt='banner' />
                  <div className='carousel-caption'>
                    <div className='view-small'>
                      <a href={slide?.cta_button_link}>
                        <h2 style={{ color: 'white ' }}>{slide?.image_text}</h2>
                      </a>
                    </div>
                    <div className='view-large'>
                      <h2 style={{ color: 'white !important' }}>{slide?.image_text}</h2>
                      <a href={slide?.cta_button_link} className='btn btn-orange'>
                        {slide?.cta_button_text}
                      </a>
                    </div>
                  </div>
                </div>
              ))}

          <div className='arrow-btn'>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleCaptions'
              data-bs-slide='prev'
            >
              <span className='carousel-control-prev-icon' aria-hidden='true'></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleCaptions'
              data-bs-slide='next'
            >
              <span className='carousel-control-next-icon' aria-hidden='true'></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroBannerSlider
