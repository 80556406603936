export const headerBtn = [
  {
    text: 'SIGN UP',
    class: 'btn-orange',
    subDec: 'FOR NEWS',
    onclick: () =>
      (window.location.href =
        'https://www.gardenofdreamsfoundation.org/sign-up'),
  },
  // {
  //   text: 'Donate',
  //   class: 'btn-blue',
  //   subDec: '',
  //   onclick: () =>
  //     (window.location.href =
  //       'https://www.classy.org/give/597491/#!/donation/checkout'),
  // },
]

export const DONATE_ROUTE = '/donate'; 