import { useEffect, useState } from 'react'
import { getArticleById } from '../../api/Category.service'
import './pagenotfound.scss'
import { getHtml } from '../../helper/utils'

const PageNotFound = () => {
  const [data, setData] = useState<any>()

  const fetchData = async () => {
    const data = await getArticleById('page-not-found')
    setData(data?.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='category'>
      {data?.content && <div dangerouslySetInnerHTML={getHtml(data?.content)} />}
    </div>
  )
}

export default PageNotFound
