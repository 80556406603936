import React from 'react'
import { placeholder } from '../../helper/iconPath'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// Image component
const Image = (props: any) => {
  let element: any
  const changeSrc = () => {
    const ImgElement = element.getElementsByTagName('img')[0]
    // when image found
    if (ImgElement) {
      ImgElement.src = placeholder
    }
  }

  /*****render*****/
  return (
    <div ref={(el) => (element = el)}>
      <LazyLoadImage onError={changeSrc} {...props} effect='blur' />
    </div>
  )
}

export default Image
