import React, { useContext, useState } from 'react'

const initialValue = {
  menu: [],
  setmenu: () => null,
  loader: true,
  setLoader: () => null,
}

const MenuContext = React.createContext<menuType>(initialValue)

export const MenuContextProvider = ({ children }: menuProp) => {
  const [menu, setmenu] = useState([])
  const [loader, setLoader] = useState(true)

  return (
    <MenuContext.Provider value={{ menu, setmenu, loader, setLoader }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => {
  const { menu, setmenu, loader, setLoader } = useContext(MenuContext)
  return { menu, setmenu, loader, setLoader }
}

type menuProp = {
  children: React.ReactNode
}

type menuType = {
  menu: any[]
  setmenu: CallableFunction
  loader: boolean
  setLoader: CallableFunction
}
