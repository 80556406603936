import React, { useContext, useState } from "react";

const initialValue = {
    category: [],
    setcategory: () => null,

}

const CategoryContext = React.createContext<categoryType>(initialValue)

export const CategoryContextProvider = ({ children }: categoryProp) => {
    const [category, setcategory] = useState([])
    return <CategoryContext.Provider value={{ category, setcategory }}>{children}</CategoryContext.Provider>
}

export const useCategoryContext = () => {
    const { category, setcategory } = useContext(CategoryContext)
    return { category, setcategory }
}

type categoryProp = {
    children: React.ReactNode
}

type categoryType = {
    category: any[],
    setcategory: CallableFunction
}