import { useNavigate } from 'react-router'
// import { gdfLogo, hamburger } from '../../../../helper/iconPath'
import GDFLOGO from './GDF_light_large.png'
import Hamburger from './hamburger.svg'

const NavImgWrapper: React.FC<navImgProps> = ({ setnavMobile, navMobile }) => {
  const navigate = useNavigate()
  return (
    <>
      <button className='navbar-brand' onClick={() => navigate('/')}>
        <img src={GDFLOGO} alt='logo' />
      </button>
      <button className='navbar-toggler' type='button' onClick={() => setnavMobile(!navMobile)}>
        <span className='navbar-toggler-icon'>
          <img src={Hamburger} alt='' />
        </span>
      </button>
    </>
  )
}

type navImgProps = {
  setnavMobile: (active: any) => void
  navMobile: boolean
}

export default NavImgWrapper
