import { useNavigate } from 'react-router-dom'
import './storycategory.scss'
import CustomButton from '../../../components/Button/Button'
import { useState } from 'react'
import Image from '../../../components/Image/Image'

const Story: React.FC<StoryProps> = ({ data }) => {
  const [visibleItems, setVisibleItems] = useState(6) // Initially, show 6  items

  const navigate = useNavigate()

  const loadMore = () => {
    // Increase the number of visible items by 6 when the "Load More" button is clicked
    setVisibleItems(visibleItems + 3)
  }

  const onCardClicked = (item: any) => {
    navigate(`/blog/${item?.uid}`)
  }

  return (
    <>
      <div className='story-wrapper'>
        {data?.slice(0, visibleItems).map((item: any, index: any) => {
          return (
            <div key={index} className='story' onClick={() => onCardClicked(item)}>
              <Image src={item?.heroBanner} alt='story-banner' className='story__image' />
              <div className='story__title'>{item?.title.length > 60 ?item?.title.substring(0,60) + "..." : item?.title}</div>
              <p className='story__content'>{item?.description}</p>
              <button className='view-load-more'>Learn more</button>
            </div>
          )
        })}
      </div>
      {visibleItems < data?.length && (
        <CustomButton title='Load More' onClick={loadMore} className='load-more-story' />
      )}
    </>
  )
}

type StoryProps = {
  data: any
}

export default Story
