import axios from 'axios'
import { END_POINTS } from './domain'

export const getTags = async (slug: any, auth?: boolean) => {
  let result = await axios.get(`${END_POINTS.tags}/${slug}`, {
    headers: { auth: auth },
  })
  if (result.data) {
    return {
      data: result.data,
      error: false,
    }
  }
  return result
}

export const getTagList = async (slug: [], auth?: boolean) => {
  let result = await axios.get(`${END_POINTS.tagList}/${slug}`, {
    headers: { auth: auth },
  })
  if (result.data) {
    return {
      data: result.data,
      error: false,
    }
  }
  return result
}
