import { cpSync } from 'fs'
import { getIntrests } from '../pages/Donate/constant'

export const getInitialVal = (val: any) => {
  if (val) {
    return val
  }
  return null
}

export const getDataById = (id: any, data: any) => {
  const foundItem = data.find((item: any) => {
    return item.articleId === id
  })
  return foundItem
}

export const getHtml = (html: string) => {
  return { __html: html }
}

export const inputHandler = (setData?: any, e?: any, name?: any, type?: string) => {
  if (type === 'checkbox') {
    setData((prev: any) => ({ ...prev, [name]: e.target.checked }))
  } else if (e.target.value) {
    setData((prev: any) => ({ ...prev, [name]: e.target.value }))
  } else if (type === 'checkboxes') {
    setData((prev: any) => ({
      ...prev,
      interests: getIntrests(prev, e, name),
    }))
  }
}

//donate form

export const getFormField = (item: any, setData?: any) => {
  switch (item.inputType) {
    case 'select':
      return (
        <div className='form-group'>
          <label className='form-control'>{item.label}</label>
          <select className='form-control' onChange={(e) => inputHandler(setData, e, item.name)}>
            {item.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )
    case 'checkboxes':
      return (
        <div className='form-group'>
          <label className='form-control'>I am interested in:</label>
          {item.options.map((option: any) => (
            <div className='form-check' key={option.id}>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id={option.id}
                onChange={(e) => inputHandler(setData, e, option.id, 'checkboxes')}
              />
              <label className='form-check-label'>{option.label}</label>
            </div>
          ))}
        </div>
      )
    case 'checkbox':
      return (
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            id='flexCheckDefault5'
            onChange={(e) => inputHandler(setData, e, item.name, item.inputType)}
            required={item.required}
          />
          <label className='form-check-label'>I am over 21 years of age*</label>
        </div>
      )
    default:
      return (
        <div className='form-group'>
          <label className='form-control'>{item.label}</label>
          <input
            className='form-control'
            type={item.inputType}
            required={item.required}
            placeholder={item.placeholder}
            onChange={(e) => inputHandler(setData, e, item.name)}
          />
        </div>
      )
  }
}

//Attendee form

export const getAttendeeFormField = (item: any, changeHandler?: any) => {
  if (item.inputType === 'input' || item.inputType === 'email') {
    return (
      <div className='form-group'>
        <label className='form-control'>{item.label}</label>
        <input
          className='form-control'
          type={item.inputType}
          placeholder={item.placeholder}
          onChange={(e) => changeHandler(e, item)}
        />
      </div>
    )
  }
  if (item.inputType === 'checkbox') {
    return (
      <div className='form-check'>
        <input
          className={item.className}
          type={item.inputType}
          id={item.id}
          onChange={(e) => changeHandler(e, item)}
        />
        <label className={item.labelClassName} htmlFor={item.htmlFor}>
          {item.label}
        </label>
      </div>
    )
  }
  if (item.inputType === 'textArea') {
    return (
      <div className='form-group'>
        <label className='form-control'>{item.label}</label>
        <textarea
          className='form-control'
          rows={5}
          placeholder={item.placeholder}
          onChange={(e) => changeHandler(e, item)}
        ></textarea>
      </div>
    )
  }
}

export const getMetaData = (data: any) => {
  const metaNames = [
    {
      name: 'description',
      content: data.meta_description,
    },
    {
      name: 'keywords',
      content: data.meta_key,
    },
    {
      name: 'title',
      content: data.meta_title,
    },
  ]
  return metaNames.map((item) => {
    return <meta name={item.name} content={item.content}></meta>
  })
}
